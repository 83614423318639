<template>
  <div class="bg-white">
    <div class="grid grid-cols-2 gap-8">
      <div class="col-span-1">
        <div class="flex flex-col space-y-1">
          <p class="mb-2 border-b border-gray-100 pb-1 text-sm text-gray-700">
            Timeslot
          </p>

          <div v-if="selectedCustomer.timezone">
            <div class="mr-2 font-semibold">
              {{
                formatInTimeZone(
                  new Date(selectedSlot.dateTime.dateTime),
                  selectedCustomer.timezone,
                  'HH:mm z'
                )
              }}
            </div>
            <p>
              {{
                formatInTimeZone(
                  new Date(selectedSlot.dateTime.dateTime),
                  selectedCustomer.timezone,
                  'eee dd MMMM'
                )
              }}
            </p>
          </div>
          <div v-else>
            <div class="mr-2 font-semibold">
              {{
                formatInTimeZone(
                  new Date(selectedSlot.dateTime.dateTime),
                  selectedSlot.dateTime.timezone,
                  'HH:mm z'
                )
              }}
            </div>
            <p>
              {{
                formatInTimeZone(
                  new Date(selectedSlot.dateTime.dateTime),
                  selectedSlot.dateTime.timezone,
                  'eee dd MMMM'
                )
              }}
            </p>
          </div>
          <p>
            {{ selectedSlot.serviceInfo.name }}
          </p>
          <p>{{ selectedSlot.duration }} minutes</p>
          <p v-if="selectedSlot.price">
            {{ selectedSlot.price.currency }} {{ selectedSlot.price.amount }}
          </p>
        </div>
      </div>
      <div class="col-span-1">
        <div class="flex flex-col space-y-1">
          <p class="mb-2 border-b border-gray-100 pb-1 text-sm text-gray-700">
            Customer
          </p>
          <p class="font-semibold">
            {{ selectedCustomer.first_name }} {{ selectedCustomer.last_name }}
          </p>
          <p>{{ selectedCustomer.email }}</p>
          <p>{{ selectedCustomer.mobile_phone_formatted }}</p>
          <p>{{ selectedCustomer.timezone }}</p>
        </div>
      </div>

      <div class="col-span-1">
        <div class="flex flex-col space-y-1">
          <p class="mb-2 border-b border-gray-100 pb-1 text-sm text-gray-700">
            Veterinarian
          </p>
          <div class="flex items-center space-x-2">
            <user-avatar
              v-if="selectedSlot.user.avatar"
              :size="2"
              :avatar="selectedSlot.user.avatar"
            />
            <p>
              {{ selectedSlot.user.display_name }}
            </p>
          </div>
        </div>
      </div>

      <div class="col-span-1">
        <div class="flex flex-col space-y-1">
          <p class="mb-2 border-b border-gray-100 pb-1 text-sm text-gray-700">
            Animal
          </p>
          <p class="font-semibold">
            {{ selectedPet.name }}
          </p>
          <p>
            {{ selectedPet.animal_type.name
            }}<span v-if="selectedPet.race">, {{ selectedPet.race }}</span>
          </p>
          <p>{{ selectedPet.birthdate }}</p>
        </div>
      </div>
    </div>

    <div class="mt-6 py-2">
      <!-- <div class="w-1/2 border-b border-gray-100 pb-1 mb-4 text-sm">
        <p>Status</p>
      </div> -->
      <div class="flex space-x-6 text-xs">
        <p
          v-if="selectedCaseType"
          class="rounded-lg border bg-beige p-1 font-semibold shadow"
        >
          {{ selectedCaseType.name }}
        </p>
        <p class="rounded-lg border border-fv-green p-1 font-semibold shadow">
          {{ paymentStatus }}
        </p>
        <p
          v-if="previousService"
          class="rounded-lg border border-gray-600 p-1 font-semibold shadow"
        >
          {{ previousService.name }}
        </p>
      </div>
    </div>

    <div class="mt-4 grid grid-cols-2 gap-x-8">
      <div class="col-span-1 space-y-2">
        <div>
          <base-label for="description"> Describe the case </base-label>
          <textarea-input id="description" v-model="appointment.description" />
        </div>
        <div
          v-if="previousAppointmentImages.length && previousId"
          class="mb-6 flex flex-col space-y-2"
        >
          <base-label> Copy images to new appointment </base-label>
          <div class="my-2 flex max-h-full items-center">
            <label
              class="mr-2 text-sm"
              :class="{ 'text-gray-500': appointment.copyImages }"
              for="copy-images"
            >
              No
            </label>
            <div class="form-switch max-h-full">
              <input
                id="copy-images"
                v-model="appointment.copyImages"
                type="checkbox"
                name="sign"
                class="form-switch-checkbox"
              />
              <label for="copy-images" class="form-switch-label display-none" />
            </div>
            <label
              class="text-sm"
              for="copy-images"
              :class="{ 'text-gray-500': !appointment.copyImages }"
            >
              Yes
            </label>
          </div>
        </div>
      </div>
    </div>

    <div
      slot="buttons"
      class="mt-4 flex items-center justify-between space-x-4 border-t py-4"
    >
      <div>
        <p v-if="uiState === 'error'" class="text-red-700">
          {{ errorMsg }}
        </p>
      </div>
      <div class="space-x-4">
        <base-button
          :loading="uiState === 'loading'"
          color="primary"
          @click="book()"
        >
          Book
        </base-button>
        <base-button @click="$emit('cancel')">
          <span>Cancel</span>
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from '@/components/user/UserAvatar';
import { AdminAppointmentApi } from '@/api';
import { formatInTimeZone } from 'date-fns-tz';

export default {
  components: {
    UserAvatar,
  },
  props: {
    selectedSlot: {
      type: Object,
      required: true,
    },
    selectedTimezone: {
      type: String,
      required: true,
    },
    selectedCustomer: {
      type: Object,
      required: true,
    },
    selectedPet: {
      type: Object,
      required: true,
    },
    selectedCountry: {
      type: Object,
      required: true,
    },
    selectedCaseType: {
      type: [Object || null],
      default: null,
    },
    previousId: {
      type: Number,
      required: true,
    },
    previousPaymentStatus: {
      type: String,
      required: true,
    },
    previousDescription: {
      type: String,
      required: true,
    },
    previousService: {
      type: Object,
      required: true,
    },
    previousAppointmentImages: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      formatInTimeZone,
      uiState: 'idle',
      errorMsg: '',
      appointment: {
        description: this.$props.previousDescription,
        caseTypeId: this.$props.selectedCaseType
          ? this.$props.selectedCaseType.caseTypeId
          : null,
        serviceId: this.$props.selectedSlot.serviceId,
        copyImages: !!this.$props.previousAppointmentImages.length,
      },
    };
  },
  computed: {
    paymentStatus() {
      switch (this.previousPaymentStatus) {
        case 'checkout_complete':
          return 'Paid by customer';
        case 'subscription':
          return 'Subscription';
        case 'cancelled':
          return 'Cancelled before completion';
        case 'insurance':
          return 'Paid through insurance';
        case 'free':
          return 'Free through FirstVet';
        case '1':
          return 'Pending, not completed.';
        default:
          return 'N/A';
      }
    },
  },

  methods: {
    async book() {
      this.uiState = 'loading';
      this.errorMsg = '';

      try {
        const payload = {
          appointmentId: this.previousId,
          bookingId: this.selectedSlot.id,
          ...this.appointment,
          ignoreMargin: 1,
        };

        const { data } = await AdminAppointmentApi.create(payload);

        this.$emit('booked-appointment', data.appointment);

        this.uiState = 'booked';
      } catch (error) {
        this.uiState = 'error';
        this.errorMsg = error.message;
      }
    },
  },
};
</script>

<style></style>
