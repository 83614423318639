var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "w-full rounded border bg-green-100 bg-opacity-25 p-4 shadow",
    },
    [
      _c(
        "p",
        {
          staticClass: "mb-6 inline-flex items-center space-x-2 font-semibold",
        },
        [
          _c("fv-icon", { attrs: { icon: "calendar" } }),
          _c("span", {}, [_vm._v("Recently booked")]),
        ],
        1
      ),
      _c(
        "ul",
        { staticClass: "flex flex-col space-y-4" },
        _vm._l(_vm.appointments, function (appointment) {
          return _c(
            "li",
            {
              key: appointment.id,
              staticClass: "inline-flex items-center space-x-2 pb-2",
            },
            [
              _c("div", [_vm._v("·")]),
              _c("div", [
                _c("p", [
                  _c("span", { staticClass: "mr-1" }, [
                    _vm._v(
                      _vm._s(
                        _vm.format(
                          new Date(appointment.booking.booking_datetime),
                          "dd MMM"
                        )
                      ) + ","
                    ),
                  ]),
                  _c("span", {}, [
                    _vm._v(
                      _vm._s(
                        _vm.format(
                          new Date(appointment.booking.booking_datetime),
                          "HH:mm"
                        )
                      )
                    ),
                  ]),
                ]),
                _c("p", [
                  _c("span", [
                    _vm._v(
                      _vm._s(appointment.animal.name) +
                        " (" +
                        _vm._s(appointment.animal.animal_type.name) +
                        ")"
                    ),
                  ]),
                  _vm._v(" - "),
                  _c("span", [_vm._v(_vm._s(appointment.user.display_name))]),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }