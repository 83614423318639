<template>
  <div class="mx-auto max-w-6xl pt-2">
    <Account />
  </div>
</template>

<script>
import Account from '@/components/account/Account';

export default {
  components: {
    Account,
  },
};
</script>
