<template>
  <div class="w-full rounded border bg-green-100 bg-opacity-25 p-4 shadow">
    <p class="mb-6 inline-flex items-center space-x-2 font-semibold">
      <fv-icon icon="calendar" /><span class="">Recently booked</span>
    </p>
    <ul class="flex flex-col space-y-4">
      <li
        v-for="appointment in appointments"
        :key="appointment.id"
        class="inline-flex items-center space-x-2 pb-2"
      >
        <div>&middot;</div>
        <div>
          <p>
            <span class="mr-1"
              >{{
                format(
                  new Date(appointment.booking.booking_datetime),
                  'dd MMM'
                )
              }},</span
            >
            <span class="">{{
              format(new Date(appointment.booking.booking_datetime), 'HH:mm')
            }}</span>
          </p>
          <p>
            <span
              >{{ appointment.animal.name }} ({{
                appointment.animal.animal_type.name
              }})</span
            >
            -
            <span>{{ appointment.user.display_name }}</span>
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { format } from 'date-fns';

export default {
  props: {
    appointments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      format,
    };
  },
};
</script>
