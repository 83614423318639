var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg-white" }, [
    _c("div", { staticClass: "grid grid-cols-2 gap-8" }, [
      _c("div", { staticClass: "col-span-1" }, [
        _c("div", { staticClass: "flex flex-col space-y-1" }, [
          _c(
            "p",
            {
              staticClass:
                "mb-2 border-b border-gray-100 pb-1 text-sm text-gray-700",
            },
            [_vm._v(" Timeslot ")]
          ),
          _vm.selectedCustomer.timezone
            ? _c("div", [
                _c("div", { staticClass: "mr-2 font-semibold" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatInTimeZone(
                          new Date(_vm.selectedSlot.dateTime.dateTime),
                          _vm.selectedCustomer.timezone,
                          "HH:mm z"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatInTimeZone(
                          new Date(_vm.selectedSlot.dateTime.dateTime),
                          _vm.selectedCustomer.timezone,
                          "eee dd MMMM"
                        )
                      ) +
                      " "
                  ),
                ]),
              ])
            : _c("div", [
                _c("div", { staticClass: "mr-2 font-semibold" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatInTimeZone(
                          new Date(_vm.selectedSlot.dateTime.dateTime),
                          _vm.selectedSlot.dateTime.timezone,
                          "HH:mm z"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatInTimeZone(
                          new Date(_vm.selectedSlot.dateTime.dateTime),
                          _vm.selectedSlot.dateTime.timezone,
                          "eee dd MMMM"
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
          _c("p", [
            _vm._v(" " + _vm._s(_vm.selectedSlot.serviceInfo.name) + " "),
          ]),
          _c("p", [_vm._v(_vm._s(_vm.selectedSlot.duration) + " minutes")]),
          _vm.selectedSlot.price
            ? _c("p", [
                _vm._v(
                  " " +
                    _vm._s(_vm.selectedSlot.price.currency) +
                    " " +
                    _vm._s(_vm.selectedSlot.price.amount) +
                    " "
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "col-span-1" }, [
        _c("div", { staticClass: "flex flex-col space-y-1" }, [
          _c(
            "p",
            {
              staticClass:
                "mb-2 border-b border-gray-100 pb-1 text-sm text-gray-700",
            },
            [_vm._v(" Customer ")]
          ),
          _c("p", { staticClass: "font-semibold" }, [
            _vm._v(
              " " +
                _vm._s(_vm.selectedCustomer.first_name) +
                " " +
                _vm._s(_vm.selectedCustomer.last_name) +
                " "
            ),
          ]),
          _c("p", [_vm._v(_vm._s(_vm.selectedCustomer.email))]),
          _c("p", [
            _vm._v(_vm._s(_vm.selectedCustomer.mobile_phone_formatted)),
          ]),
          _c("p", [_vm._v(_vm._s(_vm.selectedCustomer.timezone))]),
        ]),
      ]),
      _c("div", { staticClass: "col-span-1" }, [
        _c("div", { staticClass: "flex flex-col space-y-1" }, [
          _c(
            "p",
            {
              staticClass:
                "mb-2 border-b border-gray-100 pb-1 text-sm text-gray-700",
            },
            [_vm._v(" Veterinarian ")]
          ),
          _c(
            "div",
            { staticClass: "flex items-center space-x-2" },
            [
              _vm.selectedSlot.user.avatar
                ? _c("user-avatar", {
                    attrs: { size: 2, avatar: _vm.selectedSlot.user.avatar },
                  })
                : _vm._e(),
              _c("p", [
                _vm._v(" " + _vm._s(_vm.selectedSlot.user.display_name) + " "),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "col-span-1" }, [
        _c("div", { staticClass: "flex flex-col space-y-1" }, [
          _c(
            "p",
            {
              staticClass:
                "mb-2 border-b border-gray-100 pb-1 text-sm text-gray-700",
            },
            [_vm._v(" Animal ")]
          ),
          _c("p", { staticClass: "font-semibold" }, [
            _vm._v(" " + _vm._s(_vm.selectedPet.name) + " "),
          ]),
          _c("p", [
            _vm._v(" " + _vm._s(_vm.selectedPet.animal_type.name)),
            _vm.selectedPet.race
              ? _c("span", [_vm._v(", " + _vm._s(_vm.selectedPet.race))])
              : _vm._e(),
          ]),
          _c("p", [_vm._v(_vm._s(_vm.selectedPet.birthdate))]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "mt-6 py-2" }, [
      _c("div", { staticClass: "flex space-x-6 text-xs" }, [
        _vm.selectedCaseType
          ? _c(
              "p",
              {
                staticClass:
                  "rounded-lg border bg-beige p-1 font-semibold shadow",
              },
              [_vm._v(" " + _vm._s(_vm.selectedCaseType.name) + " ")]
            )
          : _vm._e(),
        _c(
          "p",
          {
            staticClass:
              "rounded-lg border border-fv-green p-1 font-semibold shadow",
          },
          [_vm._v(" " + _vm._s(_vm.paymentStatus) + " ")]
        ),
        _vm.previousService
          ? _c(
              "p",
              {
                staticClass:
                  "rounded-lg border border-gray-600 p-1 font-semibold shadow",
              },
              [_vm._v(" " + _vm._s(_vm.previousService.name) + " ")]
            )
          : _vm._e(),
      ]),
    ]),
    _c("div", { staticClass: "mt-4 grid grid-cols-2 gap-x-8" }, [
      _c("div", { staticClass: "col-span-1 space-y-2" }, [
        _c(
          "div",
          [
            _c("base-label", { attrs: { for: "description" } }, [
              _vm._v(" Describe the case "),
            ]),
            _c("textarea-input", {
              attrs: { id: "description" },
              model: {
                value: _vm.appointment.description,
                callback: function ($$v) {
                  _vm.$set(_vm.appointment, "description", $$v)
                },
                expression: "appointment.description",
              },
            }),
          ],
          1
        ),
        _vm.previousAppointmentImages.length && _vm.previousId
          ? _c(
              "div",
              { staticClass: "mb-6 flex flex-col space-y-2" },
              [
                _c("base-label", [_vm._v(" Copy images to new appointment ")]),
                _c(
                  "div",
                  { staticClass: "my-2 flex max-h-full items-center" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "mr-2 text-sm",
                        class: { "text-gray-500": _vm.appointment.copyImages },
                        attrs: { for: "copy-images" },
                      },
                      [_vm._v(" No ")]
                    ),
                    _c("div", { staticClass: "form-switch max-h-full" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.appointment.copyImages,
                            expression: "appointment.copyImages",
                          },
                        ],
                        staticClass: "form-switch-checkbox",
                        attrs: {
                          id: "copy-images",
                          type: "checkbox",
                          name: "sign",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.appointment.copyImages)
                            ? _vm._i(_vm.appointment.copyImages, null) > -1
                            : _vm.appointment.copyImages,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.appointment.copyImages,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.appointment,
                                    "copyImages",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.appointment,
                                    "copyImages",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.appointment, "copyImages", $$c)
                            }
                          },
                        },
                      }),
                      _c("label", {
                        staticClass: "form-switch-label display-none",
                        attrs: { for: "copy-images" },
                      }),
                    ]),
                    _c(
                      "label",
                      {
                        staticClass: "text-sm",
                        class: { "text-gray-500": !_vm.appointment.copyImages },
                        attrs: { for: "copy-images" },
                      },
                      [_vm._v(" Yes ")]
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass:
          "mt-4 flex items-center justify-between space-x-4 border-t py-4",
        attrs: { slot: "buttons" },
        slot: "buttons",
      },
      [
        _c("div", [
          _vm.uiState === "error"
            ? _c("p", { staticClass: "text-red-700" }, [
                _vm._v(" " + _vm._s(_vm.errorMsg) + " "),
              ])
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "space-x-4" },
          [
            _c(
              "base-button",
              {
                attrs: { loading: _vm.uiState === "loading", color: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.book()
                  },
                },
              },
              [_vm._v(" Book ")]
            ),
            _c(
              "base-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.$emit("cancel")
                  },
                },
              },
              [_c("span", [_vm._v("Cancel")])]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }