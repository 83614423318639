var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("narrow-layout", { staticClass: "relative min-h-full" }, [
    _c(
      "div",
      { staticClass: "mb-2 flex items-baseline justify-between border-b pb-2" },
      [
        _c("h1", { staticClass: "font-display text-4xl font-black" }, [
          _vm._v(" Book a follow-up appointment "),
        ]),
      ]
    ),
    _c("div", { staticClass: "mb-8 flex justify-between" }, [
      _c(
        "div",
        { staticClass: "inline-flex space-x-6" },
        [
          _c(
            "text-button",
            {
              on: {
                click: function ($event) {
                  _vm.selectedView = "selectAppointment"
                },
              },
            },
            [
              _c(
                "span",
                {
                  class: {
                    "fontsemibold underline":
                      _vm.selectedView === "selectAppointment",
                  },
                },
                [_vm._v("1. Select appointment")]
              ),
            ]
          ),
          _c(
            "text-button",
            {
              on: {
                click: function ($event) {
                  _vm.selectedView = "selectSlot"
                },
              },
            },
            [
              _c(
                "span",
                {
                  class: {
                    "fontsemibold underline": _vm.selectedView === "selectSlot",
                  },
                },
                [_vm._v("2. Book")]
              ),
            ]
          ),
        ],
        1
      ),
      _c("div", [
        _vm.selectedAppointment
          ? _c(
              "p",
              {
                staticClass:
                  "inline-flex flex-1 items-center space-x-2 text-right text-sm font-black text-fv-green",
              },
              [
                _c("fv-icon", {
                  staticClass: "flex-shrink-0",
                  attrs: { icon: "check-mark" },
                }),
                _c("span", [
                  _vm._v(
                    "Appointment " +
                      _vm._s(_vm.selectedAppointment.id) +
                      " is selected."
                  ),
                ]),
                _c(
                  "button",
                  {
                    staticClass:
                      "ml-2 rounded-lg px-1 text-fv-red hover:bg-fv-red hover:text-white",
                    on: { click: _vm.deSelectAppointment },
                  },
                  [_vm._v(" Undo ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
    _vm.recentlyBookedAppointments.length
      ? _c(
          "div",
          { staticClass: "absolute top-8 left-0 hidden w-1/5 xl:block" },
          [
            _c("RecentlyBooked", {
              attrs: { appointments: _vm.recentlyBookedAppointments },
            }),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.selectedView === "selectAppointment",
            expression: "selectedView === 'selectAppointment'",
          },
        ],
      },
      [
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.selectedAppointment,
                expression: "!selectedAppointment",
              },
            ],
            staticClass:
              "inline-flex max-w-xl items-center space-x-4 rounded-lg border bg-beigeLight p-4 shadow",
          },
          [
            _c("fv-icon", {
              staticClass: "flex-shrink-0",
              attrs: { icon: "warning" },
            }),
            _c("span", {}, [
              _vm._v(
                " Search for and select an appointment. The follow-up will inherit the payment status, case type and other relevant data from the previous booking. "
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-6 flex flex-col space-y-4" },
          [
            _c("SearchAppointment", {
              attrs: { "country-id": _vm.country.id },
              on: { "selected-appointment": _vm.handleStagedAppointment },
            }),
            _vm.stagedAppointmentId
              ? _c(
                  "div",
                  {
                    staticClass:
                      "max-w-xl transition-all duration-300 ease-in-out",
                  },
                  [
                    _c(
                      "AppointmentInfo",
                      {
                        attrs: {
                          "booking-id": _vm.stagedAppointmentId,
                          "in-scheduler": false,
                        },
                        on: {
                          "fetched-appointment": function ($event) {
                            _vm.stagedAppointment = $event
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "button" }, slot: "button" },
                          [
                            _c(
                              "base-button",
                              {
                                staticClass: "w-full",
                                attrs: { color: "primary" },
                                on: { click: _vm.selectAppointment },
                              },
                              [_vm._v(" Select ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.selectedView === "selectSlot",
            expression: "selectedView === 'selectSlot'",
          },
        ],
      },
      [
        !_vm.selectedAppointment
          ? _c(
              "p",
              {
                staticClass:
                  "inline-flex max-w-xl items-center space-x-4 rounded-lg border bg-beigeLight p-4 shadow",
              },
              [
                _c("fv-icon", {
                  staticClass: "flex-shrink-0",
                  attrs: { icon: "warning" },
                }),
                _c("span", [
                  _vm._v(
                    "You need to select an appointment to book a follow-up."
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.selectedAppointment
          ? _c(
              "div",
              [
                !!_vm.selectedSlot
                  ? _c("BookingCard", {
                      staticClass: "mb-4 rounded border px-4 pt-6 shadow",
                      attrs: {
                        "selected-slot": _vm.selectedSlot,
                        "selected-timezone": _vm.currentTimezone,
                        "selected-customer": _vm.selectedAppointment.user,
                        "selected-pet": _vm.selectedAppointment.animal,
                        "selected-country": _vm.country,
                        "previous-case-type": _vm.selectedAppointment.case_type,
                        "previous-id": _vm.selectedAppointment.id,
                        "previous-payment-status":
                          _vm.selectedAppointment.status,
                        "previous-description":
                          _vm.selectedAppointment.description,
                        "previous-service": _vm.selectedAppointment.service,
                        "previous-appointment-images":
                          _vm.selectedAppointment.images,
                      },
                      on: {
                        cancel: function ($event) {
                          _vm.selectedSlot = null
                        },
                        "booked-appointment": _vm.handleBookedAppointment,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.selectedAppointment
          ? _c("div", [
              _c(
                "div",
                {},
                [
                  _c("AvailableSlots", {
                    attrs: {
                      display: "row",
                      "use-date": true,
                      country: _vm.country,
                      services: _vm.services,
                      "animal-types": _vm.animalTypes,
                      "us-states": _vm.usStates,
                      booking: true,
                      "selected-animal-type":
                        _vm.selectedAppointment.animal.type_id,
                    },
                    on: {
                      selected: _vm.handleSelectedSlot,
                      timezone: _vm.handleSelectedTimezone,
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }