<template>
  <div class="max-w-lg">
    <base-label for="appointmentSearch">
      Search for any keyword (customer or animal name, ID, email, etc.)
    </base-label>
    <v-select
      id="appointmentSearch"
      v-model="searchStr"
      name="appointmentSearch"
      class="font-medium rounded text-gray-700 w-full mt-2"
      label="label"
      :options="options"
      :filterable="false"
      :clearable="true"
      :loading="uiState === 'searching'"
      :clear-search-on-select="false"
      :reduce="appointment => appointment.bookingId"
      @search="search"
      @clear="rawOptions = []"
      @input="$emit('selected-appointment', $event)"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { AdminAppointmentApi } from '@/api';

export default {
  props: {
    countryId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      rawOptions: [],
      searchStr: null,
      uiState: 'idle',
      errorMsg: '',
    };
  },
  computed: {
    options() {
      return this.rawOptions.map(appointment => {
        return {
          bookingId: appointment.bookingId,
          label: `${appointment.bookingDate} ${appointment.bookingTime} - ${
            appointment.animalName
          } (${appointment.animalTypeKey})
          - ${appointment.userFirstName} ${appointment.userLastName}`,
        };
      });
    },
  },
  methods: {
    // eslint-disable-next-line func-names
    search: debounce(async function(search) {
      await this.searchAppointments(search);
    }, 500),

    async searchAppointments(searchStr) {
      this.uiState = 'searching';
      try {
        const { data } = await AdminAppointmentApi.search(
          this.countryId,
          searchStr
        );
        if (data.appointments) {
          this.rawOptions = data.appointments;
        } else {
          this.rawOptions = [];
        }
        this.uiState = 'idle';
        console.log(searchStr, data);
        return data;
      } catch (error) {
        this.uiState = 'error';
        this.errorMsg = error.message;
        return Promise.reject(error);
      }
    },
  },
};
</script>

<style>
</style>
