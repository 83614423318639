<template>
  <narrow-layout class="relative min-h-full">
    <div class="mb-2 flex items-baseline justify-between border-b pb-2">
      <h1 class="font-display text-4xl font-black">
        Book a follow-up appointment
      </h1>
    </div>
    <div class="mb-8 flex justify-between">
      <div class="inline-flex space-x-6">
        <text-button @click="selectedView = 'selectAppointment'">
          <span
            :class="{
              'fontsemibold underline': selectedView === 'selectAppointment',
            }"
            >1. Select appointment</span
          >
        </text-button>
        <text-button @click="selectedView = 'selectSlot'">
          <span
            :class="{ 'fontsemibold underline': selectedView === 'selectSlot' }"
            >2. Book</span
          >
        </text-button>
      </div>
      <div>
        <p
          v-if="selectedAppointment"
          class="inline-flex flex-1 items-center space-x-2 text-right text-sm font-black text-fv-green"
        >
          <fv-icon icon="check-mark" class="flex-shrink-0" />
          <span>Appointment {{ selectedAppointment.id }} is selected.</span>
          <button
            class="ml-2 rounded-lg px-1 text-fv-red hover:bg-fv-red hover:text-white"
            @click="deSelectAppointment"
          >
            Undo
          </button>
        </p>
      </div>
    </div>
    <div
      v-if="recentlyBookedAppointments.length"
      class="absolute top-8 left-0 hidden w-1/5 xl:block"
    >
      <RecentlyBooked :appointments="recentlyBookedAppointments" />
    </div>
    <div v-show="selectedView === 'selectAppointment'">
      <p
        v-show="!selectedAppointment"
        class="inline-flex max-w-xl items-center space-x-4 rounded-lg border bg-beigeLight p-4 shadow"
      >
        <fv-icon icon="warning" class="flex-shrink-0" />
        <span class="">
          Search for and select an appointment. The follow-up will inherit the
          payment status, case type and other relevant data from the previous
          booking.
        </span>
      </p>

      <div class="mt-6 flex flex-col space-y-4">
        <SearchAppointment
          :country-id="country.id"
          @selected-appointment="handleStagedAppointment"
        />

        <div
          v-if="stagedAppointmentId"
          class="max-w-xl transition-all duration-300 ease-in-out"
        >
          <AppointmentInfo
            :booking-id="stagedAppointmentId"
            :in-scheduler="false"
            @fetched-appointment="stagedAppointment = $event"
          >
            <div slot="button">
              <base-button
                color="primary"
                class="w-full"
                @click="selectAppointment"
              >
                Select
              </base-button>
            </div>
          </AppointmentInfo>
        </div>
      </div>
    </div>

    <div v-show="selectedView === 'selectSlot'">
      <p
        v-if="!selectedAppointment"
        class="inline-flex max-w-xl items-center space-x-4 rounded-lg border bg-beigeLight p-4 shadow"
      >
        <fv-icon icon="warning" class="flex-shrink-0" />
        <span>You need to select an appointment to book a follow-up.</span>
      </p>

      <div v-if="selectedAppointment">
        <BookingCard
          v-if="!!selectedSlot"
          class="mb-4 rounded border px-4 pt-6 shadow"
          :selected-slot="selectedSlot"
          :selected-timezone="currentTimezone"
          :selected-customer="selectedAppointment.user"
          :selected-pet="selectedAppointment.animal"
          :selected-country="country"
          :previous-case-type="selectedAppointment.case_type"
          :previous-id="selectedAppointment.id"
          :previous-payment-status="selectedAppointment.status"
          :previous-description="selectedAppointment.description"
          :previous-service="selectedAppointment.service"
          :previous-appointment-images="selectedAppointment.images"
          @cancel="selectedSlot = null"
          @booked-appointment="handleBookedAppointment"
        />
      </div>

      <div v-if="selectedAppointment">
        <div class="">
          <AvailableSlots
            display="row"
            :use-date="true"
            :country="country"
            :services="services"
            :animal-types="animalTypes"
            :us-states="usStates"
            :booking="true"
            :selected-animal-type="selectedAppointment.animal.type_id"
            @selected="handleSelectedSlot"
            @timezone="handleSelectedTimezone"
          />
        </div>
      </div>
    </div>
  </narrow-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AvailableSlots from '@/components/admin/schedule/schedule-displays/AvailableSlots';
import BookingCard from '@/components/admin/booking/BookingCard';
import SearchAppointment from '@/components/admin/booking/SearchAppointment';
import AppointmentInfo from '@/components/admin/AppointmentInfo';
import RecentlyBooked from '@/components/admin/booking/RecentlyBooked';
import { AnimalApi, CountryApi } from '@/api';
import { US_COUNTRY_ID } from '@/config/countries';

export default {
  components: {
    AvailableSlots,
    BookingCard,
    SearchAppointment,
    AppointmentInfo,
    RecentlyBooked,
  },

  data() {
    return {
      selectedView: 'selectAppointment',
      services: [],
      animalTypes: [],
      usStates: [],

      selectedSlot: null,
      stagedAppointmentId: null,
      selectedAppointment: null,
      stagedAppointment: null,

      recentlyBookedAppointments: [],

      currentTimezone: null,
    };
  },
  computed: {
    ...mapGetters('admin', ['country']),
  },

  async mounted() {
    const promises = [this.setServices(), this.setAnimalTypes()];
    if (this.country.id === US_COUNTRY_ID) {
      promises.push(this.setUsStates());
    }
    await Promise.all(promises);
  },

  methods: {
    ...mapActions('admin/schedule', ['fetchServices']),

    handleSelectedSlot(slot) {
      this.selectedSlot = slot;
    },

    handleStagedAppointment(id) {
      this.stagedAppointmentId = id;
    },

    selectAppointment() {
      this.selectedAppointment = this.stagedAppointment;
      this.selectedView = 'selectSlot';
      this.selectedSlot = null;
    },

    deSelectAppointment() {
      this.stagedAppointment = null;
      this.selectedAppointment = null;
      this.stagedAppointmentId = null;
    },

    handleSelectedTimezone(timezone) {
      this.currentTimezone = timezone;
    },

    handleBookedAppointment(appointment) {
      this.deSelectAppointment();
      this.recentlyBookedAppointments.push(appointment);
      this.selectedView = 'selectAppointment';
    },

    async setServices() {
      const services = await this.fetchServices();
      this.services = services.filter(
        service => service.key !== 'veterinary_chat'
      );
    },

    async setUsStates() {
      const { data } = await CountryApi.fetchUsStates();
      console.log('Fetched us states!', data);
      this.usStates = data.us_states;
    },

    async setAnimalTypes() {
      const { data } = await AnimalApi.fetchAnimalTypes();
      this.animalTypes = data.animal_types;
    },
  },
};
</script>
