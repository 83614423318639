var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "max-w-lg" },
    [
      _c("base-label", { attrs: { for: "appointmentSearch" } }, [
        _vm._v(
          " Search for any keyword (customer or animal name, ID, email, etc.) "
        ),
      ]),
      _c("v-select", {
        staticClass: "font-medium rounded text-gray-700 w-full mt-2",
        attrs: {
          id: "appointmentSearch",
          name: "appointmentSearch",
          label: "label",
          options: _vm.options,
          filterable: false,
          clearable: true,
          loading: _vm.uiState === "searching",
          "clear-search-on-select": false,
          reduce: function (appointment) {
            return appointment.bookingId
          },
        },
        on: {
          search: _vm.search,
          clear: function ($event) {
            _vm.rawOptions = []
          },
          input: function ($event) {
            return _vm.$emit("selected-appointment", $event)
          },
        },
        model: {
          value: _vm.searchStr,
          callback: function ($$v) {
            _vm.searchStr = $$v
          },
          expression: "searchStr",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }